import './ReportBenchmark.scss';
import { api } from "../../services";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import { FilterBlock, FormInfo, ReportFilters, HelpCenter, TabsHeader, Warning, ReportFavorites } from './components';
import { Loading } from "../../components";
import { message, Modal, Button } from 'antd';
import { ModalSavedView } from "./modal";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import ReportContent from "./reports/ReportContent";
import { ReportFavoritesHeader } from './components/ReportFavorites';
import { StepCategory, StepPeriods, StepSubcategory } from "./steps";
import { useParams } from 'react-router-dom';
import { withTranslation } from "react-i18next";

const ReportBenchmark = ({
  comparisonGroups,
  createNewBenchmarkView,
  forms,
  getBenchmarkComparisonGroups,
  getBenchmarkDataForCategory,
  getBenchmarkDataForIndicator,
  getBenchmarkDrillDown,
  getBenchmarkDrillDownForGroups,
  getBenchmarkFavoriteViews,
  getBenchmarkForms,
  getBenchmarkOrgDataForCategory,
  getBenchmarkPeriods,
  getBenchmarkSettings,
  getBenchmarkSpecialView,
  getBenchmarkViewList,
  setBenchmarkFavoriteViews,
  settingsBenchmark,
  t,
  user
}) => {
  const { id, formTitle } = useParams();

  const activeForm = forms?.find(form => form.id === id);
  const SHOW_ALL = t('show_all');

  const [activeCategory, setActiveCategory] = useState(null);
  const [activeCategoryTitle, setActiveCategoryTitle] = useState(t('show_all'));
  const [comparisonGroupIds, setComparisonGroupIds] = useState(JSON.parse(window.localStorage.getItem('defaultComparisonGroups')) || []);
  const [activeComparisonGroups, setActiveComparisonGroups] = useState(comparisonGroups.filter((item) => comparisonGroupIds?.includes(item.id)) || []);
  const [activeFavoriteView, setActiveFavoriteView] = useState(JSON.parse(window.sessionStorage.getItem('activeFavoriteView')) ?? "");
  const [activeGroup, setActiveGroup] = useState(null);
  const [activeGroupTitle, setActiveGroupTitle] = useState(t('show_all'));
  const [activeOrganization, setActiveOrganization] = useState(null);
  const [allGroups, setAllGroups] = useState(null);
  const [category, setCategory] = useState([]);
  const [checkedList, setCheckedList] = useState(null);
  const [currentForm, setCurrentForm] = useState(null);
  const [defaultActiveCollapseKey, setDefaultActiveCollapseKey] = useState([]);
  const [drillDownData, setDrillDownData] = useState(null);
  const [elementsData, setElementsData] = useState(null);
  const [endDate, setEndData] = useState(null);
  const [favoriteIndicators, setFavoriteIndicators] = useState(JSON.parse(window.sessionStorage.getItem('favoriteIndicators')) || []);
  const [gridColumns, setGridColumns] = useState([]);
  const [isWarning, setIsWarning] = useState(false);
  const [mapFile, setMapFile] = useState(null);
  const [optionList, setOptionList] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);
  const [periodsData, setPeriodsData] = useState([]);
  const [periodsDefault, setPeriodsDefault] = useState(null);
  const [periodType, setPeriodType] = useState('month');
  const [reportData, setReportData] = useState(null);
  const [savedFavorites, setSavedFavorites] = useState(JSON.parse(window.sessionStorage.getItem('savedFavorites')) || []);
  const [showLoaderCollapse, setShowLoaderCollapse] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [specialOrganisations, setSpecialOrganisations] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [steps, setSteps] = useState(null);  

  useEffect(() => {
    getBenchmarkComparisonGroups();
    const groups = comparisonGroups.filter((item) => comparisonGroupIds.includes(item.id));
    setActiveComparisonGroups(groups);
  }, [comparisonGroupIds]);

  useEffect(() =>{
    if(!(activeFavoriteView))
      return;

    if(activeFavoriteView === '') {      
      setFavoriteIndicators([]);
      return;
    }

    const indicators = savedFavorites?.find(x => x.name === activeFavoriteView)?.indicators;
    if(!(indicators))
      return;
    
    setFavoriteIndicators(indicators || []);
  }, [activeFavoriteView]);

  useEffect(() => {getBenchmarkForms()}, []);

  useEffect(() => {
      //grid columns
      let columns = [];

      // Active organisation
      columns.push({
        Tooltip: activeOrganization?.title,
        name: activeOrganization?.title,
        title: activeOrganization?.title
      });

      // Groups
      activeComparisonGroups.forEach(group => {
        columns.push({
          Tooltip: group.name,
          name: group.name,
          title: group.name
        });
      });

      // Individual
      if(specialOrganisations.length > 0) {
        columns = [
          ...columns,
          ...specialOrganisations?.map(org => {
            return {
              Tooltip: org?.title,
              name: org?.title,
              title: org?.title
            };
          })
        ];
      }

      // Extra
      if(settingsBenchmark?.extra_columns){
        let extra_cols = Object.keys(settingsBenchmark?.extra_columns).map(key => {
          let col = settingsBenchmark?.extra_columns[key];
          return {
            Tooltip: `${t(key)} ${t('extra_columns_definition')}`,
            name: col,
            title: col
          };
        });
        
        if (checkedList != null)
          extra_cols = extra_cols.filter(c => checkedList.includes(c.title));

        columns = columns.concat(extra_cols);
      }

      setGridColumns(columns);
  }, [activeOrganization, activeComparisonGroups, checkedList, settingsBenchmark, specialOrganisations]);

  useEffect(() => {
    const organization = user?.data?.organisations?.find((item) => item.id === user?.data?.organisation);
    setActiveOrganization(organization);
  }, []);

  useEffect(() => {
    if (steps < 2)
      return;

    if(!(periodsDefault))
      return;

    getBenchmarkFavoriteViews({
      form_id: id,
      organisation_id: activeOrganization?.id
    })
    .then((response) => {
      if(response.values.length > 0){
        setSavedFavorites(response.values[0].indicators);
        console.log(response.values[0].indicators);
      }
    })
    .catch((error) => console.error(error));
  }, [periodsDefault, activeOrganization, id]);

  useEffect(() =>{
    window.sessionStorage.setItem('savedFavorites', JSON.stringify(savedFavorites));
  }, [savedFavorites]);

  useEffect(() => {
    if (settingsBenchmark) {
      const optionListArray = Object.values(settingsBenchmark?.extra_columns)?.filter(item => !!item);

      setOptionList(optionListArray)
      setCheckedList(optionListArray);
    } 
    else {
      setShowLoaderCollapse(true);
      getBenchmarkSettings()
        .then((response) => {
          const { data: { settings } } = response;
          const optionListArray = Object.values(settings?.extra_columns)?.filter(item => !!item);

          setOptionList(optionListArray);
          setCheckedList(optionListArray);

          return response;
        })
        .catch((e) => console.error(e))
        .finally(() => setShowLoaderCollapse(false));
    }
  }, [activeOrganization]);

  useEffect(() => {
    if (id) {
      setShowLoaderCollapse(true)
      api.questionnaires.getProgressForm(id)
        .then((response) => {
          const { status, data } = response;
          if (status === 200)
            setMapFile(data?.map_file);
        })
        .catch((e) => console.error(e));

      api.questionnaires.getAllCollapseElements(id)
        .then(async (response) => {
          const { status, data } = response;
          await data;
          if (status === 200 && data.webform.elements) {
            setCategory(data.webform.elements);
            setAllGroups(data.webform.elements[0]?.elements);
            setActiveGroup(data.webform.elements[0]?.elements[0]);
            setActiveCategory(data.webform.elements[0]);
          }
        })
        .catch((error) => console.error(error));

      getPeriods(id);
    }
  }, [activeOrganization, currentForm]);

  useEffect(() => {
    if (steps === 4)
      generateReport();
  }, [specialOrganisations]);

  useEffect(() => {
    setActiveCategoryTitle(t('show_all'));
    setActiveGroupTitle(t('show_all'));
    setAllGroups(category[0]?.elements);
    setActiveGroup(category[0]?.elements[0]);
    setActiveCategory(category[0]);
    setComparisonGroupIds([]);
  }, [currentForm]);

  useEffect(() => {
    window.localStorage.setItem('defaultComparisonGroups', JSON.stringify(comparisonGroupIds));
  }, [comparisonGroupIds]);

  useEffect(() => {
    if(favoriteIndicators)
      window.sessionStorage.setItem('favoriteIndicators', JSON.stringify(favoriteIndicators));
  }, [favoriteIndicators]);

  const getPeriods = (id) => {
    setShowLoaderCollapse(true);

    getBenchmarkPeriods({ data: id, is_full: 1, organisation: activeOrganization?.id ?? user?.data?.organisation })
      .then(async (response) => {
        const { data, status } = await response;
        await data;

        if (data?.values?.data?.length && status === 200) {
          setSteps(1);
          setPeriodsData(data?.values?.data);
        }
        else if (status === 200) {
          setPeriodsData([])
          setSteps(5);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setShowLoaderCollapse(false))
  }

  // Find category by Title and set it as the active category.
  // Also update the subcategory (groups) array with the subcategories of this category.
  const renderGroupData = (value) => {
    return category?.map(item => {
      if (item.title === value) {
        setAllGroups(item.elements);
        setActiveCategory(item);
        setActiveGroupTitle(t('show_all'));
      }
    })
  };

  const handlerCheckedFilters = (value) => {setCheckedList(value)}

  // Find subcategory by Title and set it as the active subcategory when found
  const renderActiveGroup = (value) => {
    const group = allGroups?.find(item => item.title === value);
    if (!!group)
      setActiveGroup(group);

    return group;
  };

  const setHandlerChangeForm = (value) => {setCurrentForm(value)}

  const handleCategoryChange = (value) => {
    setActiveCategoryTitle(value);
    renderGroupData(value);

    // When category is changed to "all", return to step 2 (category selection)
    if (value === SHOW_ALL) {
      setSteps(2);
      return;
    }

    // Check if category contains subcategories 
    if (category[0]?.elements?.length) {
      setSteps(3);
    }
    else {
      // Retrieve benchmark data when this category has no subcategories
      setSteps(4);
      generateReport(activeCategory);
    }
  };

  const handlerChangeSubcategory = (value) => {
    setActiveGroupTitle(value);
    const group = renderActiveGroup(value);
    setSteps(value === SHOW_ALL ? 3 : 4);
    return value !== SHOW_ALL ? generateReport(group) : null;
  }

  // Retrieve benchmark data from the Laravel backend API
  const generateReport = async (data, specialOrg) => {
    // Prevent report generation when no period is selected
    if (startDate === null || endDate === null)
      return;

    setShowLoaderCollapse(true);

    const indicator = data?.webform_indicator || activeGroup?.webform_indicator || activeCategory?.webform_indicator;
    const period_start = startDate;
    const period_end = endDate;
    const period_type = periodType;

    const key = data?.webform_key || activeGroup?.webform_key || activeCategory?.webform_key;

    const getElementsTask = api.questionnaires.getElements(id, key)
      .then((response) => {
        if (response.status < 300)
          console.log(response);
          setElementsData(response.data);
      })
      .catch((e) => console.error('error ', e));

    const getOrganisationDataTask = getBenchmarkOrgDataForCategory({
      batch: false,
      calculation_type: 'average',
      organization:
        specialOrganisations?.length || specialOrg?.length
          ? specialOrganisations?.length
            ? [...specialOrganisations?.map(item => item.id), activeOrganization?.id]
            : [...specialOrg?.map(item => item.id), activeOrganization?.id]
          : [activeOrganization?.id],
      indicator,
      period_start,
      period_end,
      period_type,
    })
      .then((response) => {
        if (response.status < 300)
          setOrganizationData(response.data);
      })
      .catch((error) => console.error(error));

    const getDrillDownTask = comparisonGroupIds == null || comparisonGroupIds.length == 0 ? null : getBenchmarkDrillDownForGroups({
      batch: true,
      indicator,
      groups: comparisonGroupIds,
      period_start,
      period_end,
      calculation_types: ['average']
    })
      .then((response) => {
        const { data } = response;
        setDrillDownData(data);
      })
      .catch((e) => console.error('error ', e));

    const getReportDataTask = getBenchmarkDataForCategory({
      batch: true,
      calculation_types: Object.keys(settingsBenchmark?.extra_columns),
      group_by: 'time_period',
      groups: comparisonGroupIds,
      indicator,
      period_start,
      period_end,
      period_type,
    })
      .then((response) => {
        const { data, status } = response;
        if (status === 200)
          setReportData(data?.values);
      })
      .catch((e) => console.error(e.message));

    await Promise.allSettled([getElementsTask, getOrganisationDataTask, getDrillDownTask, getReportDataTask])
      .then(() => setShowLoaderCollapse(false))
      .catch((e) => console.error(e.message));
  };

  const handlePeriodChange = (value) => {
    setPeriodsDefault(value);
    const currentPeriod = periodsData?.find((item) => item?.name === value);
    if (currentPeriod) {
      setStartDate(moment.parseZone(currentPeriod?.condition?.period_start).format('YYYY-MM-DD HH:mm:ss'));
      setEndData(moment.parseZone(currentPeriod?.condition?.period_end).format('YYYY-MM-DD HH:mm:ss'));
      setPeriodType(currentPeriod?.condition?.period_name);
    }

    setSteps(value === SHOW_ALL ? 1 : 2);
  };

  const getActiveOrganization = (value) => {setActiveOrganization(value)}

  const handlerOpenAllCollapse = (value) => {
    if (steps === 4) {
      if (value) {
        const defaultKey = elementsData?.webform?.elements?.map(item => item.element_indicator);
        setDefaultActiveCollapseKey([...defaultKey]);
      } 
      else
        setDefaultActiveCollapseKey([]);
    }
  }

  const handlerOpenViewModal = (bool) => setShowModal(bool);

  const handlerCloseModal = () => setShowModal(false);

  const handlerSavedView = async (name) => {
    const paramsSaveView = {
      period: periodsDefault,
      category: activeCategoryTitle,
      subcategory: activeGroupTitle,
      organization: activeOrganization,
      groups: comparisonGroupIds,
      periodStart: startDate,
      periodEnd: endDate,
      periodType: periodType,
      formId: id,
      specialOrganisations: specialOrganisations,
      name,
    }

    setShowLoaderCollapse(true);

    await createNewBenchmarkView(paramsSaveView, 'new')
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          getBenchmarkViewList(id);
          message.success(`${t('view_saved_successfully')}: ${data?.data?.name}`)
        }
      })
      .catch((e) => message.error(e.text))
      .finally(() => setShowLoaderCollapse(false));

    handlerCloseModal();
  }

  const handlerGetSpecialView = async (id) => {
    if (!!!id) {
      console.error("Cannot get SpecialView of empty id");
      return null;
    }

    await getBenchmarkSpecialView(id)
      .then(async (response) => {
        const { status, data: { data: { view_params } } } = await response;

        if (status === 200) {
          setSteps(1);
          setPeriodsDefault(view_params.period);
          setStartDate(view_params?.periodStart);
          setEndData(view_params?.periodEnd);
          setPeriodType(view_params?.periodType);
          setActiveCategoryTitle(view_params.category);
          setActiveGroupTitle(view_params.subcategory);
          setSpecialOrganisations(view_params.specialOrganisations);

          return category?.forEach(item => {
            if (item.title === view_params.category) {
              setActiveCategory(item);
              const currentSubcategory = item?.elements?.find(val => val.title === view_params.subcategory)
              setActiveGroup(currentSubcategory);
              setActiveOrganization(view_params.organization);
              setComparisonGroupIds(view_params.groups);
              setSteps(4);
            }
          });
        }
      })
      .catch((e) => console.error(e));
  };

  const handlerDownloadBenchmarkData = async () => {
    const indicator = activeGroup?.webform_indicator || activeCategory?.webform_indicator;
    const period_start = startDate;
    const period_end = endDate;
    setShowLoaderCollapse(true);

    await getBenchmarkDataForCategory({
      batch: true,
      calculation_types: Object.keys(settingsBenchmark?.extra_columns),
      group_by: 'time_period',
      groups: comparisonGroupIds,
      indicator,
      period_start,
      period_end,
      period_type: periodType,
      format: 'batch_xlsx'
    })
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        const link = document.createElement('a')
        link.href = data?.file;
        link.setAttribute(
          'download',
          `${formTitle.toLowerCase().replace(/ /ig, '_')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch((e) => message.error(e.message))
    .finally(() => setShowLoaderCollapse(false));
  }

  const handlerSetSpecialOrganisations = (value) => {
    setIsWarning(value.length > settingsBenchmark?.minimum_number_organisations);
    setSpecialOrganisations(value);
  }

  const handlerSetSteps = (value) => setSteps(value);

  const handleToggleFavorite = (title, indicator) => {
    let fav = favoriteIndicators.find(item => item.indicator === indicator);
    if(fav)
      setFavoriteIndicators(favoriteIndicators.filter(item => item.indicator !== indicator));
    else {
      setFavoriteIndicators([
        ...favoriteIndicators,
        {
          title, 
          indicator,
          category: activeCategory?.webform_indicator
        }
      ]);
    }
  }

  const handleSaveFavoriteView = (name) => {
    const item = {
      id: name,
      name: name,
      indicators: favoriteIndicators
    }; 

    const filtered = savedFavorites.filter(x => x.name !== name);
    const newSavedFavorites = [
      ...filtered,
      item,
    ];

    setSavedFavorites(newSavedFavorites);
    
    window.sessionStorage.setItem('savedFavorites', JSON.stringify(savedFavorites));
    setBenchmarkFavoriteViews({
      form_id: id,
      organisation_id: activeOrganization?.id,
      indicators: newSavedFavorites
    });
  }

  const handleDeleteFavoriteView = (name) => {
    setSavedFavorites(savedFavorites.filter(x => x.name !== name));
    if(activeFavoriteView === name)
      setActiveFavoriteView("");
  }

  const handleActiveFavoriteView = (name) => {setActiveFavoriteView(name)}

  useEffect(() => {
    window.sessionStorage.setItem('activeFavoriteView', JSON.stringify(activeFavoriteView));

    // Upload to server.
  }, [activeFavoriteView]);

  let content = (<Loading />);

  if (steps === 1)
    content = (<StepPeriods t={t} periods={periodsData} actionFunction={handlePeriodChange} />);

  if (steps === 2)
    content = (<StepCategory t={t} category={category} actionFunction={handleCategoryChange} />);

  if (steps === 3)
    content = (<StepSubcategory t={t} allGroups={allGroups} actionFunction={handlerChangeSubcategory} />);

  if (steps === 5)
    content = (<p>{t('no_permissions')}</p>);

  if (steps === 4) {
    content = (
      <div className={'report__tabs'}>
        <TabsHeader gridColumns={gridColumns}/>
        <div className={'report__analyse'}>
          {
            showLoaderCollapse
              ? <Loading />
              : <>
                <ReportContent
                  activeOrganization={activeOrganization}
                  checkedList={checkedList}
                  comparisonGroups={activeComparisonGroups}
                  defaultActiveKey={defaultActiveCollapseKey}
                  drillDownData={drillDownData}
                  drillDownGroups={comparisonGroupIds}
                  elementsData={elementsData?.webform?.elements}
                  endDate={endDate}
                  favoriteIndicators={favoriteIndicators}
                  getBenchmarkDrillDown={getBenchmarkDrillDown}
                  isDrillDown={true}
                  mapFile={mapFile}
                  onToggleFavorite={handleToggleFavorite}
                  optionList={optionList}
                  organizationData={organizationData?.values}
                  periodType={periodType}
                  reportData={reportData}
                  settingsBenchmark={settingsBenchmark}
                  specialOrganisations={specialOrganisations}
                  startDate={startDate}
                  t={t}
                />
                {settingsBenchmark?.download_benchmark_data === 'yes' &&
                  <Button
                    className={'download__benchmark'}
                    icon={<DownloadOutlined />}
                    onClick={handlerDownloadBenchmarkData}
                    size={'large'}
                    type={'primary'}
                  >
                    {t('download')}
                  </Button>
                }
              </>
          }
        </div>
      </div>
    )
  }

  return (
    <div className={'report-pages'}>
      {showLoaderCollapse && <Loading />}
      <Modal
        title={t('saved_views')}
        footer={null}
        open={showModal}
        onCancel={handlerCloseModal}
      >
        <ModalSavedView handlerCancelClick={handlerCloseModal} handlerSavedView={handlerSavedView} />
      </Modal>

      <div className={'reports'}>
        <div className={'report-form'}>
          <FormInfo
            activeForm={activeForm}            
            forms={forms}
            setHandlerChangeForm={setHandlerChangeForm}
          />

          {steps >= 2 && steps !== 5 && (
            <>
            <ReportFavoritesHeader 
              activeFavoriteView={activeFavoriteView} 
              handleActiveView={handleActiveFavoriteView}
              handleDeleteView={handleDeleteFavoriteView}
              handleSaveView={handleSaveFavoriteView}
              savedFavorites={savedFavorites} 
              t={t}
              />

            {(favoriteIndicators?.length ?? 0) > 0 && (
              <ReportFavorites
                active_organisation={activeOrganization?.id}
                calculation_types={Object.keys(settingsBenchmark?.extra_columns ?? {})}
                comparison_groups={comparisonGroupIds}
                elementsData={elementsData?.webform?.elements}
                favoriteIndicators={favoriteIndicators}
                gridColumns={gridColumns}
                handleDeleteFavorite={handleToggleFavorite}
                individual_organisations={specialOrganisations?.length ? specialOrganisations?.map(item => item.id) : []}
                key={activeOrganization + comparisonGroupIds.join('-') + specialOrganisations}
                period_end={endDate}
                period_start={startDate}
                settingsBenchmark={settingsBenchmark}
                t={t}
              />
            )}
            </>
          )}

          {steps !== 5 && (
            <ReportFilters
              activeCategoryTitle={activeCategoryTitle}
              activeGroupTitle={activeGroupTitle}
              allGroups={allGroups}
              category={category}
              handleCategoryChange={handleCategoryChange}
              handlerChangeSubcategory={handlerChangeSubcategory}
              handlerGetSpecialView={handlerGetSpecialView}
              handlerOpenViewModal={handlerOpenViewModal}
              handlePeriodChange={handlePeriodChange}
              handlerSetSteps={handlerSetSteps}
              periodsData={periodsData}
              periodsDefault={periodsDefault}
              steps={steps}
              t={t}
            />
          )}
        </div>

        {steps !== 5 && (
          <FilterBlock
            checkedList={checkedList}
            comparisonGroupIds={comparisonGroupIds}
            comparisonGroups={comparisonGroups}
            generateReport={generateReport}
            getActiveOrganization={getActiveOrganization}
            handlerCheckedFilters={handlerCheckedFilters}
            handlerOpenAllCollapse={handlerOpenAllCollapse}
            handlerSetSpecialOrganisations={handlerSetSpecialOrganisations}
            optionList={optionList}
            setComparisonGroupIds={setComparisonGroupIds}
            steps={steps}
            t={t}
          />
        )}
        
        {
          steps === 4 
          && (isWarning || reportData?.is_duplicates)
          && <Warning number={settingsBenchmark?.minimum_number_organisations} t={t} warningOrg={isWarning}/>
        }
        {content}
      </div>

      <HelpCenter />
    </div>
  );
};

export default withTranslation('benchmark')(ReportBenchmark);
