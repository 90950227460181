import BranchesOutlined from "@ant-design/icons/lib/icons/BranchesOutlined";
import CaretRightOutlined from "@ant-design/icons/lib/icons/CaretRightOutlined";
import { Collapse } from "antd";
import React, {useMemo} from "react";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";

import './SearchCollapse.scss';

const { Panel } = Collapse;

const SearchCollapse = ({
 searchData,
 searchResultValue,
 t,
 periodName,
 value
}) => {

  const setTitle = (value) => {
    if (value === null)
      return t('category')

    if (value.parent === null)
      return t('subcategory')

    return t('indicators')
  };

  const setPeriods = (periodName) => {
    let result = [];
    result.push(periodName)

    if (searchData?.parent === null)
      return result;

    if (searchData?.parent?.parent === null) {
      result.push(searchData?.parent?.title);
      return result;
    } 

    if (searchData?.parent?.parent?.parent === null) {
      result.push(searchData?.parent?.parent?.title);
      result.push(searchData?.parent?.title);
      return result;
    }
  }

  const panelHeader = useMemo(() => {
    const splittedSearch = searchResultValue.toLowerCase().split(' ');
    const splittedTitle = searchData?.title?.toLowerCase()?.split(' ');
    let result = [];

    splittedTitle.forEach(item => {
      if (splittedSearch.includes(item) || splittedSearch.find(x => item.includes(x)))
        result.push({text: item, bold: true});
      else
        result.push({text: item, bold: false});
    });
    
    return (
      <div className={'panel__header'}>
        <div className={'titles'}>
          <span>{setTitle(searchData?.parent)}</span>
          <div className={'report__title'}>
            {result.map((item) => item.bold ? <span>{item.text} </span> : <>{item.text} </>)}
          </div>
        </div>

        <div className={'steps'}>
          <div className={'icons'}>
            <BranchesOutlined />
          </div>
          {
            setPeriods(periodName)?.map((item, index) => (
              <div className={'step'} key={index}>
                {item ? <p className={'step__title'}>{item}</p> : null}
                <RightOutlined />
              </div>
            ))
          }
        </div>
      </div>
    )
  }, [searchData, value]);


  return (
    <Collapse
      className={'collapse__block'}
      expandIcon={({ isActive }) => (
          searchData?.parent?.parent
          ? <CaretRightOutlined rotate={isActive ? 90 : 0} />
          : <button>{t('to_show')}</button>
        )
      }
      expandIconPosition={'right'}
    >
      <Panel
        header={panelHeader}
        key="1"
      >
      </Panel>
    </Collapse>
  );
};

export default SearchCollapse;
